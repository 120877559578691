import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import throttle from 'lodash/throttle'
import rootReducer from '../reducers'
import combinedSaga from '../sagas'
import { saveState } from './localStorage'

function createAppStore(initialState) {
  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [sagaMiddleware]

  const enhancers = [applyMiddleware(...middlewares)]

  const store = createStore(rootReducer, initialState, compose(...enhancers))

  sagaMiddleware.run(combinedSaga)

  return store
}

let store = null

function configureStore (initialState) {
  if (store) return store

  store = createAppStore(initialState)
  store.subscribe(throttle(() => {
    saveState({
      configurationData: store.getState().configurationData
    })
  }, 1000))
  return store
}

function getStore() {
  return store
}

configureStore.getStore = getStore

export default configureStore