import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import posthog from 'posthog-js'
import * as Integrations from '@sentry/integrations'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'

console.log(
  `${process.env.REACT_APP_ENV}: ${process.env.REACT_APP_NAME} version ${process.env.REACT_APP_VERSION}`
);

if (process.env.NODE_ENV === 'production') {
  const tagManagerArgs = {
    gtmId: 'GTM-NFSZHS2'
  };
  TagManager.initialize(tagManagerArgs);
  ReactGA.initialize('G-38CVN6C3PK')
  posthog.init('phc_skOXD1My3nqVyQI6GFRFxkdIqnyJy4WltBdq6xV9lc8')
  Sentry.init({
    dsn: 'https://6bfec988c38b47778e7001458b075c51@o373641.ingest.sentry.io/5248581',
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
    integrations: [
      new Integrations.CaptureConsole({
        levels: ['error']
      }),
      new posthog.SentryIntegration(posthog, 'alemhealth-ql', '5248581'),
    ],
    ignoreErrors: ['Non-Error promise rejection captured']
  })
}

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
