import React, { Component } from 'react'
import { isMobile } from './utils/device-helpers'
import { loadState } from './store/localStorage'
import { Provider } from 'react-redux'
import configureStore from './store/configureStore'
import DesktopPageLayout from './routers/DesktopPageLayout'
import MobilePageLayout from './routers/MobilePageLayout'
import './styles/main.scss'
import './styles/app.less'

const persistedState = loadState()
const store = configureStore(persistedState)

class App extends Component {
  constructor(props) {
    super(props);
  }
  render () {
    const isMobileDevice = isMobile.any()
    return (
      <Provider store={store}>
        {isMobileDevice ? <MobilePageLayout /> : <DesktopPageLayout />}
      </Provider>
    )
  }
}

export default App
