import { IP_VERIFY_SERVICE } from '../config/constants'
export const getCurrentIPInfoAPICall = async () => {
  try {
    const response = await window.fetch(`${IP_VERIFY_SERVICE}?token=b00a4f8da7be54`)
    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`);
    }
    const data = await response.json()
    return data
  } catch (e) {
    console.error('ERROR: ', e)
    return null
  }
}
